import { Box, Card, Chip, Typography, Button, Snackbar, Alert,CircularProgress  } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hooks/useUser";
import useSWR from "swr";
import OperationsTable from "../components/tables/OperationsTable";
import { swrFetcher } from "../utils/fetch";
import { getOperationStatus, getPriceString } from "../utils/tools";
import { appFetch } from "../utils/fetch";
import { numberWithCommas } from "../utils/appConfig";

function DashboardPage() {
  const { data: myCards } = useSWR("/api/card/user", swrFetcher);

  const { data, mutate } = useSWR("/api/operation/user", swrFetcher);//הבאת כל העסקאות בכרטיס מהמונגו

  // הפרדת הנתונים
  //const myOperations = data?.data || [];  // עסקאות רגילות
  //const myUpayOperations = data?.upayData || [];  // עסקאות יופיי
  const myOperations = data|| [];  // עסקאות רגילות

  // שורת הבעיה עם mutate נוסף
   const { data: lastOperation, mutate: mutateLastOperation } = useSWR(
  "/api/operation/last",
  swrFetcher
);

  const [operationStatus, setOperationStatus] = useState("confirmed");
  const [totalBalance, setTotalBalance] = useState(0);
  const [latestOperationDate, setLatestOperationDate] = useState("");
  const { mosad } = useContext(UserContext);
  const [ upayCard, setUpayCard ] = useState({});
  const [ lastBalanceDate, setLastBalanceDate ] = useState();
  const [transactionsStates, setTransactionsStates] = useState({});
  const [flatTransactionsStates, setFlatTransactionsStates] = useState({});
  const [loading, setLoading] = useState();
  const [organization, setOrganization] = useState(null);
  const [expenseItems, setExpenseItems] = useState(null);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      if (!mosad?.id) return; // לא לבצע קריאה אם אין mosad.id
      setLoading(true);
      try {
        const res = await appFetch(
          `/api/zohoMosads/organizationByMosadID/${mosad.field13.id}`,
          {
            method: "GET",
          }
        );
        if (!res.ok) {
          throw new Error("Failed to fetch organization");
        }
        const organizationData = await res.json();
        setOrganization(organizationData.data[0]); // שמירת הארגון ב-state
        setExpenseItems(organizationData.data[0].Expense_items);
      } catch (error) {
        console.error("Error fetching organization:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []); // תלות ב-mosad.id כדי לבצע קריאה רק כאשר הוא משתנה

  useEffect(() => {
    setLoading(true);
    if (mosad) {
        const fetchBalance = async () => {//הבאת נתוני הכרטיס מיופיי
            try {
                const response = await appFetch(`/api/card/getCardBalanceByUser`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ mosad }),
                });

                if (!response.ok) throw new Error("Failed to fetch balance");

                const data = await response.json();
                const cards = data.cards;
                console.log(cards);

                // חיפוש כרטיס עם סוג 'upay'
                        const card = cards.find(card => card.cardType === "upay");

                        if (card) {
                            // עדכון מצב היתרה
                            setUpayCard(card)

                            // שליפת התאריך האחרון עם פורמט מותאם
                            const lastIndex = card.balances.length - 1;
                            const lastUpdatedDate = formatDate(card.balances[lastIndex].updatedAt);
                            setLastBalanceDate(lastUpdatedDate);
                        }
            } catch (error) {
                console.error("Error fetching balance:", error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchBalance(); // קריאה לפונקציה האסינכרונית
    }
}, [mosad]);

const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const handleGetTransactions = async () => {
    setLoading(true); // הפעלת מצב טעינה
    try {
      await getTransactions();
      setSnackbar({
        open: true,
        message: "רענון עסקאות הושלם בהצלחה",
        severity: "success",
      });
    } catch (error) {
      console.error("Error refreshing transactions:", error);
      setSnackbar({
        open: true,
        message: "שגיאה ברענון העסקאות. נסה שוב",
        severity: "error",
      });
    } finally {
      setLoading(false); // ביטול מצב טעינה
    }
  };

const getTransactions = async () => {
  try {
    const { CardCid, CardLast4Numbers, CustomerIdentifier } = upayCard;
    const response = await appFetch(`/api/card/getTransactions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ CardCid, CardLast4Numbers, CustomerIdentifier }),
    });

    if (!response.ok) throw new Error("Failed to fetch transactions");

    const data = await response.json();
    const transactions = data;
    setTransactionsStates(transactions);

    const flatTransactions = data.reduce((acc, item, index) => {
      const transactions = item.CardTransaction || [];
      const flattenedTransactions = transactions.map((transaction, i) => ({
        id: `${index}-${i}`,
        amount: transaction.OrigAmount,
        date: transaction.DateBuy ? transaction.DateBuy.slice(0, 10) : "",
        customerName: transaction.CustomerName,
        merchantName: transaction.MerchantName,
        transactionType: transaction.TranTypeDescription,
      }));
      return [...acc, ...flattenedTransactions];
    }, []);

    setFlatTransactionsStates(flatTransactions);
    await mutate();

    // Show success snackbar
    setSnackbar({
      open: true,
      message: "עסקאות הועלו בהצלחה!",
      severity: "success",
    });
  } catch (error) {
    console.error("Error fetching transactions:", error);
    // Show error snackbar
    setSnackbar({
      open: true,
      message: "שגיאה בעת העלאת העסקאות. נסה שוב.",
      severity: "error",
    });
  }
};


// console.log(myCards)
// let newObj = {
//   balance: 20000,
//   holderName: "John Doe",
//   number: "1234567890",
//   organization: "XYZ Corp",
//   user: "some_user_id",
//   __v: 0,
//   _id: "some_id"
// };

// // Pushing the new object into the array
// myCards.push(newObj);

// // Now arrayOfObjects contains the new object
// console.log(myCards);

  // let totalfees = 0;
  const feesOperations = [];
  const groupedOperationsByStatus = {
    waiting: [],
    "waiting-for-confirm": [],
    confirmed: [],
    rejected: [],
  };
  myOperations?.forEach((item) => {
    if (item.typeCode === 722) {
      // totalfees += item.amount;
      feesOperations.push(item);
    } else {
      const operationStatus = getOperationStatus(item);
      if (!operationStatus&&item.cardType==="upay") {//מיועד לפעולות ביופיי שכרגע אין להם סטטוס במצב ראשוני
        if (item.description === "טעינת כרטיסים ארגונים") {
          groupedOperationsByStatus["confirmed"].push(item);
        }else{
          groupedOperationsByStatus["waiting"].push(item);
        }
      } else {
        groupedOperationsByStatus[operationStatus].push(item);
      }
    }
  });

  useEffect(() => {
    if (myCards) {
      let total = 0;
      myCards.forEach((item) => {
        total += item.balance;
      });
      setTotalBalance(total);
    }
  }, [myCards]);

  useEffect(() => {
    if (myOperations) {
      const latestDate = getLatestOperationDate(lastOperation);
      setLatestOperationDate(latestDate);
    }
  }, [myOperations]);


  const getOperationCount = (status) =>
    groupedOperationsByStatus[status].length;
    

  const getLatestOperationDate = (operation) => {
    if (!operation || !operation.date) return "";
    const date = new Date(operation.createdAt);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const tableData = groupedOperationsByStatus["waiting"];
  
    

  return (
    <Container>
      <Box
        display={"flex"}
        sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
        justifyContent="center"
        gap={1.5}
      >
        <InsightCard
          color={"appPink.main"}
          label="עסקאות לטיפול"
          value={getOperationCount("waiting")}
        />
        <InsightCard
          color={"appGreen.main"}
          label="עסקאות אושרו"
          value={getOperationCount("confirmed")}
        />
        <InsightCard
          color={"appYellow.main"}
          label="עסקאות בהמתנה"
          value={getOperationCount("waiting-for-confirm")}
        />
        <InsightCard
          color={"appBlack.main"}
          label="עסקאות נדחו"
          value={getOperationCount("rejected")}
        />
        <InsightCard
          color={"appPink.main"}
          label="יתרה"
          value={`${myCards?.length ? getPriceString(totalBalance) : "-"} ₪`}
        />
      </Box>

      {latestOperationDate && (
        <Typography mt={10} mb={2} variant="h4" color="appPink.main">
          הנתונים מעודכנים ל {latestOperationDate}
        </Typography>
      )}
      <Typography variant="h5" mt={4} mb={2}>
        {loading ? (
          "מעדכן נתונים עבור כרטיס נטען"
        ) : upayCard?.balance !== undefined && !isNaN(upayCard?.balance) ? (
          <>
            יתרה בכרטיס נטען: {numberWithCommas(upayCard?.balance)} ₪
            <Typography variant="h5" mt={1}>
              נכון לתאריך: {lastBalanceDate}
            </Typography>
          </>
        ) : (
          "כרטיס נטען: אין נתוני יתרה זמינים"
        )}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={6} mb={3}>
        <Typography variant="h3" color="appPink.main">
          עסקאות לטיפול
        </Typography>
        <Button
      variant="contained"
      color="primary"
      onClick={handleGetTransactions}
      sx={{ ml: 2 }}
      disabled={loading} // מניעת לחיצה חוזרת בזמן טעינה
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : "רענון עסקאות"}
    </Button>
      </Box>
      <OperationsTable
        data={tableData}
        mutate={mutate}
        organization={organization}
        expenseItems={expenseItems}
      />
      <Typography mt={6} mb={2} variant="h3" color="appPink.main">
        עסקאות שטופלו
      </Typography>
      <Box
        display={"flex"}
        alignItems={"center"}
        p={0.5}
        gap={0.5}
        sx={{
          border: "1px solid #eee",
          borderRadius: 20,
          width: "fit-content",
          margin: 1.5,
        }}
      >
        <StatusChip
          onClick={() => setOperationStatus("confirmed")}
          label={`אושר (${getOperationCount("confirmed")})`}
          isActive={operationStatus === "confirmed"}
        />
        <StatusChip
          onClick={() => setOperationStatus("waiting-for-confirm")}
          label={`בהמתנה (${getOperationCount("waiting-for-confirm")})`}
          isActive={operationStatus === "waiting-for-confirm"}
        />
        <StatusChip
          onClick={() => setOperationStatus("rejected")}
          label={`נדחה (${getOperationCount("rejected")})`}
          isActive={operationStatus === "rejected"}
        />
      </Box>
      <OperationsTable
        data={groupedOperationsByStatus[operationStatus]}
        mutate={mutate}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};
export function InsightCard({ value, color, label }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "6rem",
        textAlign: "center",
        width: { xs: "30%", md: "19%" },
        padding: { xs: "10px" },

        // minWidth:{md:"19%"}
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "1.4rem", md: "2.5rem" } }}
        color={color}
      >
        {value}
      </Typography>
      <Typography
        margin={"5px"}
        sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
        variant="h4"
      >
        {label}
      </Typography>
    </Card>
  );
}

function StatusChip({ label, isActive, onClick }) {
  return (
    <Chip
      size="medium"
      label={label}
      color={isActive ? "appGreen" : "disabledText"}
      variant={isActive ? "filled" : "outlined"}
      onClick={onClick}
      sx={{ width: "5.5rem", border: "none" }}
    />
  );
}

export default DashboardPage;
