import { Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactsIcon from '@mui/icons-material/Contacts';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DrawerHeader, Main } from "./AdminLayout";
import logo2 from "../../assets/images/logo2.png"
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import appConfig from "../../utils/appConfig";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { UserContext } from "../../hooks/useUser";
import  LoadingScreen from "../LoadingScreen";
import useSWR from "swr";
import { swrFetcher } from "../../utils/fetch";
import { appFetch } from "../../utils/fetch";

const drawerWidth = 240;

const Layout = ({ children}) => {

  const { mosad, setMosad, user, logout,paymentsData, setPaymentsData, setLoadCardData,organization,setOrganization } = useContext(UserContext);

  const [load, setLoad ] = useState(true);


  // let id = 0;
  //  if (mosad){
  //   id = mosad.ID;
  //  }

  // const url = appConfig.apiUrls.getPaymentsData + id;

  useEffect(() => {
    // בדיקה אם mosad.ID קיים
    if (!mosad?.id) return;
  
    (async () => {
      try {
        const res = await appFetch(`api/zohoPayments/operationsByMosadID/${mosad.id}`, {
          method: 'GET',
        });
        if (!res.ok) {
          throw new Error('Failed to fetch students');
        }
        const data = await res.json();
        console.log(data);
  
        // פיצול הנתונים לשני מערכים
        const loadedData = data.reduce((acc, item) => {
          if (item.operatorData[0]?.field2 === 'הטענת כרטיסים') {
            acc.loadCardData.push(item);
          } else {
            acc.otherData.push(item);
          }
          return acc;
        }, { loadCardData: [], otherData: [] });
  
        // הגדרת state עם הנתונים המפוצלים
        setPaymentsData(loadedData.otherData);
        setLoadCardData(loadedData.loadCardData)
        console.log(loadedData.otherData);
        console.log(loadedData.loadCardData);
        setPaymentsData(loadedData.otherData.flatMap(item => item.results || []));
  
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setLoad(false);
      }
    })();
  }, [mosad]);
  

  useEffect( () => {
    if( !mosad ){
        (async () => {
            try {

              // Get general info:
              // let response = await fetch(`${appConfig.apiUrls.getMosadData}${user.email}`);
              // const data = await response.json();
              // console.log(data[0]);
              // const mosad = data[0];

              let response = await appFetch(`api/zohoOrganizations/MosadBySemelMosad/${user.email}`, {
                method: 'GET',
              });
              if (!response.ok) {
                throw new Error('Failed to fetch mosad');
              }
              const data = await response.json();
              console.log(data.data[0]);
              const mosad = data.data[0];

              // Create array
              //mosad.pages = [];

              // Get info from WP REST API:
              if( user?.organization?.organizationId ){
                // response = await fetch(`${appConfig.apiUrls.getOrganization}${user.organization.organizationId}`);
                // const organization = await response.json();


                let response = await appFetch('api/zohoOrganizations/details'); 
                setOrganization(response);
                if (!response.ok) {
                  throw new Error('Failed to fetch data');
                }
                const organization = await response.json();
                mosad.logo = organization.logo_link;
           
                // if(mosad.Pages!== "null"){
                //   for (let i = 0; i < organization.pages.length; i++) {
                //     if (mosad.Pages.includes(organization.pages[i])){
                //       mosad.pages.push(organization.pages[i])
                //     }
                //   }
                // }else{
                //   mosad.pages = organization.pages;
                // }

                if(mosad.pages.length=== 0){
                  mosad.pages = organization.pages;
                }
                 
                mosad.budget_items = organization.budget_items;
              }
              // if( user && !user.organization){
              //   mosad.pages=['payments', 'students', 'invoices']
              // }
              setMosad(mosad);


            } catch (error) {
              console.log(error);
              alert("שגיאה בטעינת הנתונים" );
              logout();
            }
        })();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const theme = useTheme();

  const [open, setOpen] = useState( !appConfig.is_mobile );
  const navigate = useNavigate();
  const location = useLocation();
  
  // const { data: myPayments, mutate } = useSWR(url,
  //   swrFetcher
  // );

  const feesPayments = [];
  const groupedPaymentsByStatus = {
    חדש: [], 
    "אושר על ידי הגננת": [],
    "נדחה על ידי הגננת": [],
    "בוצע תשלום": []
  };

  paymentsData?.forEach((item) => {
    if (item.typeCode === 722) {
      // totalfees += item.amount;
      feesPayments.push(item);
    } else {
      const paymentStatus = item.Reportingstatus;
      groupedPaymentsByStatus[paymentStatus].push(item);
    }
  });
 
const payments= groupedPaymentsByStatus["חדש"].length;

  const NAV = [
    { label: "ראשי", path: "", icon: <PersonIcon /> },
    { label: "פעולות באשראי", path: "activities", icon: <PriceChangeIcon /> },
  
    {label: (<>תשלומים{" "}<span style={{ color:payments>0? "red":"black" }}>{`(${payments})`}</span></>),path: "payments",icon: <ReceiptIcon />,},
    {
      label: (
        <>
          הטענות בכרטיס{" "}
          <span
            style={{
              display: "inline-block",
              width: "8px", // גודל הנקודה
              height: "8px",
              backgroundColor: "green", // צבע ירוק
              borderRadius: "50%", // צורה עגולה
              marginLeft: "10px", // רווח מהטקסט
              position: "relative",
              top: "-3px", // מרים את הנקודה למעלה
            }}
            title="חדש"  // כאן הכיתוב שיופיע במצב hover
          ></span>
        </>
      ),
      path: "cardLoading",
      icon: <ReceiptIcon />,
    },    
    { label: "תלמידים", path: "students", icon: <ReceiptLongIcon /> },
    { label: "מפעילים מאושרים", path: "operators", icon: <ContactsIcon /> },
    { label: "חשבוניות", path: "invoices", icon: <FileCopyIcon /> },
  ];

  if( !mosad ){
    return <LoadingScreen />;
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
      <CssBaseline />

      
        <Drawer className="drawer-mobile" sx={{
            // zIndex: 1,
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          PaperProps={{
            sx: {
              transform: open
                ? "translateX(0px) !important"
                : `translateX(-${drawerWidth}px) !important`,
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <DrawerHeader>
            {mosad?.logo && <img src={mosad?.logo} alt="" style={{width: "80px", marginLeft: "auto", height: "55px", objectFit: "contain" }}/> }
          <IconButton onClick={() => setOpen(!open)}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {NAV.map(({ label, path, icon }) => {
              if( path && mosad.pages && !mosad.pages.includes(path) ) return <Fragment key={label}></Fragment>;
              const isActive = location.pathname === "/dashboard" + (path ? "/" + path : path );
              // add validation
              return (
                <ListItem key={label} disablePadding onClick={() => appConfig.is_mobile ? setOpen(false) : ""}>
                  <ListItemButton
                    onClick={() => navigate(path)}
                    sx={{ bgcolor: isActive ? "#eee" : "transparent" }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <div style={{position: "absolute", bottom: 15, textAlign: "center", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center"}}>
            <img style={{ width: "60%",}} src={logo2} alt="" />
          </div>
        </Drawer>

        <Main className="main-mobile" open={open} style={{padding: 0}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2, ml: 2, ...(open && { display: "none" }), position: "absolute", top: "20px" }}>
            <MenuIcon />
          </IconButton>
          <Header />
          <Box component={"main"} px={{ xs: 0, sm: 1.5 }} mt={2}>
            {children}
          </Box>
          <Footer />
        </Main>
      </Box>
    </>
  );
};

export default Layout;
