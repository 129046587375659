import {
  AppBar,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/Check";
import { appFetch } from "../../utils/fetch";
import { useSnackbar } from "notistack";

function UploadInvoiceDialog({
  open,
  onClose,
  selectedItem,
  onSuccess,
  onError,
  expenseItems,
  organization,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [filename, setFilename] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedExpenseItem, setSelectedExpenseItem] = useState(""); // state לסעיף ההוצאה
  const fileInputRef = useRef(null);
  const noteInputRef = useRef(null);
  const formRef = useRef(null);

  const onCloseHandler = () => {
    fileInputRef.current.value = null;
    noteInputRef.current.value = null;
    setFilename("");
    setSelectedExpenseItem(""); // איפוס סעיף ההוצאה
    onClose();
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(formRef.current);
      formData.append("expenseItem", selectedExpenseItem); // הוספת סעיף ההוצאה לנתונים שנשלחים

      const res = await appFetch(
        `/api/operation/${selectedItem._id}/upload-invoice`,
        {
          method: "POST",
          body: formData,
          defaultContentType: true,
        }
      );
      if (!res.ok) throw new Error();
      enqueueSnackbar("החשבונית נשמרה");
      onSuccess();
      onCloseHandler();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCloseHandler}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            color={"white"}
            component="div"
          >
            העלאת חשבונית
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack
        component={"form"}
        onSubmit={onSubmit}
        ref={formRef}
        p={3}
        spacing={2}
        display="flex"
        alignItems="center"
      >
        {/* שדה טקסט להערה */}
        <TextField name="note" label="הערה" size="small" ref={noteInputRef} fullWidth />

        {/* שדה בחירה לסעיף ההוצאה */}
        {expenseItems?.length > 0 && (
            <FormControl fullWidth size="small">
              <InputLabel id="expense-item-label">סעיף הוצאה</InputLabel>
              <Select
                labelId="expense-item-label"
                id="expense-item-select"
                name="expenseItem"
                value={selectedExpenseItem} // הערך הנבחר
                onChange={(e) => setSelectedExpenseItem(e.target.value)} // עדכון הערך
              >
                {expenseItems.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        {/* כפתור לבחירת קובץ */}
        <Button
          disabled={loading}
          variant={!filename ? "text" : "outlined"}
          startIcon={!filename ? <AttachFileIcon /> : <CheckIcon />}
          onClick={() => fileInputRef.current.click()}
          fullWidth
        >
          {!filename ? "בחר קובץ" : "נבחר"}
        </Button>
        <input
          type="file"
          name="file"
          accept="image/*,application/pdf"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(ev) => {
            setFilename(ev.target.files?.[0]?.name);
          }}
        />

        {/* כפתור לשליחת הטופס */}
        <Button
          disabled={!filename || loading}
          variant="contained"
          type="submit"
          startIcon={<UploadFileIcon />}
          fullWidth
        >
          העלה
        </Button>
      </Stack>
    </Dialog>
  );
}

export default UploadInvoiceDialog;
