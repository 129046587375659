import React, { useState, useContext } from 'react';
import { Typography, Card, Box, TextField, Button, Select, MenuItem, IconButton } from '@mui/material';
import { Container } from '@mui/system';
import { UserContext } from '../../hooks/useUser';
import LoadingScreen from '../../components/LoadingScreen';
import { appFetch } from '../../utils/fetch';
import AddIcon from '@mui/icons-material/Add';

const NewReportPage = () => {
  const { operator, mosads } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [reportName, setReportName] = useState(''); // שדה לשם הדיווח
  const [sessions, setSessions] = useState([
    { mosad: '', sessionDate: '', type: '', amount: '', Name: '' },
  ]);

  const handleInputChange = (index, field, value) => {
    const updatedSessions = [...sessions];
    updatedSessions[index][field] = value;
    setSessions(updatedSessions);
  };

  const handleAddRow = () => {
    setSessions([
      ...sessions,
      { mosad: '', sessionDate: '', type: '', amount: '', Name: '' },
    ]);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await appFetch('/api/zohoOperators/addReport', {
        method: 'POST',
        body: JSON.stringify({ operator: operator, reportName, sessions }), // שליחת שם הדיווח
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw new Error('Failed to submit report');
      }
      alert('הדיווח נשלח בהצלחה!');
    } catch (error) {
      console.error('Error submitting report:', error);
      alert('שגיאה בשליחת הדיווח');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ height: '100vh' }}>
      <Typography mb={2} variant="h3" color="appPink.main">
        דיווח חדש
      </Typography>
      <Typography mb={2} variant="h5" color="appBlack.main">
        שם מפעיל: {operator.Name}
      </Typography>
      <Typography mb={2} variant="h5" color="appBlack.main">
        דואר אלקטרוני: {operator.Email}
      </Typography>

      {/* שדה שם דיווח */}
      <TextField
        label="שם הדיווח"
        value={reportName}
        onChange={(e) => setReportName(e.target.value)} // עדכון שם הדיווח
        fullWidth
        sx={{ mb: 2 }}
      />

      {sessions.map((session, index) => (
        <Card key={index} sx={{ mb: 2, p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Select
              value={session.mosad?.id || ''}
              onChange={(e) => {
                const selectedMosad = mosads.find((mosad) => mosad.id === e.target.value);
                handleInputChange(index, 'mosad', selectedMosad);
              }}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                בחר מוסד
              </MenuItem>
              {mosads.map((mosad) => (
                <MenuItem key={mosad.id} value={mosad.id}>
                  {mosad.Name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="תאריך הפעלה"
              type="date"
              value={session.sessionDate}
              onChange={(e) => handleInputChange(index, 'sessionDate', e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="שם ההפעלה"
              value={session.Name}
              onChange={(e) => handleInputChange(index, 'Name', e.target.value)}
              fullWidth
            />
            <Select
              value={session.type}
              onChange={(e) => handleInputChange(index, 'type', e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                בחר סוג הפעלה
              </MenuItem>
              <MenuItem value="טיול">טיול</MenuItem>
              <MenuItem value="חוג">חוג</MenuItem>
              <MenuItem value="מסיבה">מסיבה</MenuItem>
              <MenuItem value="הופעה">הופעה</MenuItem>
              <MenuItem value="טיול - נסיעות">טיול - נסיעות</MenuItem>
              <MenuItem value="טיול - אתרים">טיול - אתרים</MenuItem>
              <MenuItem value="גן מדע - נסיעות">גן מדע - נסיעות</MenuItem>
              <MenuItem value="גן מדע - רכז יקום">גן מדע - מרכז יקום</MenuItem>
              <MenuItem value="שירותים ורכישות">שירותים ורכישות</MenuItem>
              <MenuItem value="הזנה">הזנה</MenuItem>
              <MenuItem value="גן מורשת">גן מורשת</MenuItem>
              <MenuItem value="תקציב עירייה">תקציב עירייה</MenuItem>
              <MenuItem value="ביטות">ביטות</MenuItem>
            </Select>
            <TextField
              label="סכום מבוקש"
              type="number"
              value={session.amount}
              onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
              fullWidth
            />
          </Box>
        </Card>
      ))}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          שלח
        </Button>
        <IconButton onClick={handleAddRow} color="primary">
          <AddIcon />
        </IconButton>
      </Box>

      {loading && <LoadingScreen />}
    </Container>
  );
};

export default NewReportPage;
