import React, { useState, useEffect, useRef } from "react";
import { RecaptchaVerifier, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink, signInWithPhoneNumber } from "firebase/auth";
import { fireAuth } from "../plugins/firebase";
import { Link } from "react-router-dom";
import { validateByRules } from "../utils/validators";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/system";
import { useUser } from "../hooks/useUser";

import backgroundImage from "../assets/images/image 1-2-2.png";

function LoginPage() {
  const { user } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [displayLoad, setDisplayLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const [emailLogin, setEmailLogin] = useState(true);
  const [smsLogin, setSmsLogin] = useState(false);
  const [otLink, setOtLink] = useState(false);
  const appVerifier = useRef("");
  const confirmationResult = useRef("");


  useEffect(() => {//בדיקה אם יש משתמש מחובר
    onAuthStateChanged(fireAuth, async (userAuth) => {//אם יש משתמש אז userAuth הוא לא נאל
      if (userAuth && !user) {
        setDisplayLoad(true);
        setDisplayLoad(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const auth = getAuth();//קבלת אובייקט אימות
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) email = window.prompt("Please provide your email for confirmation");
      signInWithEmailLink(auth, email, window.location.href)//כניסה עם אימייל כאן
        .then((result) => { window.localStorage.removeItem("emailForSignIn") })
        .catch((error) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loginHandler = async (ev, isGoogleSignin) => {
    ev.preventDefault();
    if (!isGoogleSignin) {
      const invalid = validateForm();
      if (invalid) {
        console.error("is not valid");
        return;
      }
    }
    const auth = getAuth();
    !isGoogleSignin && setLoading(true);
    setServerError("");
    try {
      if (isGoogleSignin) {
        const result = await signInWithPopup(auth, new GoogleAuthProvider());
        GoogleAuthProvider.credentialFromResult(result);
        // Signed in
      } else {
        await signInWithEmailAndPassword(auth, email, password);// זה מה שיקרא בפועל כי מועבר לפונ' isGoogleSignin===false בשורה 293
      }
      setLoading(true);
    } catch (err) {
      applyServerError(err)
    } finally {
      setLoading(false);
    }
  };

  const applyServerError = (err) => {
    const errorCode = err.code;
    const errorMessage = err.message;
    if (
      errorCode === "auth/user-not-found" ||
      errorCode === "auth/wrong-password"
    ) {
      setServerError("שם משתמש ו/או הסיסמא שגויים");
    } else if (
      errorCode === "auth/popup-closed-by-user" ||
      errorCode === "auth/cancelled-popup-request" ||
      errorCode === "auth/admin-restricted-operation"
      
    ) {
        setServerError("ארעה שגיאה, נא לפנות לשירות הלקוחות");
    } else if ( errorCode === "auth/invalid-phone-number" ) {
        setServerError("פורמט טלפון לא תקין");
    } else if (errorCode === "auth/too-many-requests") {
      setServerError(
        "החשבון נחסם זמנית עקב ניסיונות התחברות רבים. ניתן לאפס סיסמא בכפתור ׳שכחתי סיסמא׳, או לנסות להתחבר מאוחר יותר."
      );
    } else {
      setServerError(
        errorMessage ? errorMessage : "ארעה שגיאה, נא לפנות לשירות הלקוחות"
      );
    }
  }

  const validateForm = () => {
    const hasErrors =
      validateByRules("email", email) || validateByRules("password", password);
    return hasErrors;
  };

  const sendCode = (ev) => {
    ev.preventDefault();
    setServerError("");
    if(!phone) return;
    setLoading(true);
    const auth = getAuth();
    if (!appVerifier.current) appVerifier.current = new RecaptchaVerifier("recaptcha-container",{size: "invisible"},auth);
    signInWithPhoneNumber(auth, "+972" + phone.slice(1), appVerifier.current)
    .then(_confirmationResult => {
      setSmsLogin(true);
      confirmationResult.current = _confirmationResult;
    }).catch(err => applyServerError(err) )
    .finally(() => setLoading(false) )
  };

  const smsHandler = (ev) => {
      setServerError("");
      ev.preventDefault();
      if(!code) return;
      setLoading(true);
      confirmationResult.current.confirm(code)
        .then(result => console.log(result))
        .catch(err => applyServerError(err))
        .finally(() => setLoading(false) )
  };


  const otpEmail = (ev) => {
    ev.preventDefault();
      setServerError("");
      if(!email) return;
      setLoading(true);
      const auth = getAuth();
      window.localStorage.setItem("emailForSignIn", email);
      sendSignInLinkToEmail(auth, email, {
          url: `${window.location.origin}/login`,
          handleCodeInApp: true,
        })
        .then(result => alert("סיסמא נשלחה למייל שלכם"))
        .catch(err => applyServerError(err))
        .finally(() => setLoading(false) )
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box>
      {process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_ENV_MODE === "test" && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "99999",
              color: "white",
              bgcolor: "orangered",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 1,
            }}
          >
            TEST MODE
          </Box>
        )}
      {displayLoad && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: "9999",
            color: "#D527B7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      )}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          background: `url("${backgroundImage}") center #D527B7`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack bgcolor={"white"} alignItems={"center"} component="form" minWidth={"250px"} p={10}>
          <Typography variant="h3">ברוכים הבאים</Typography>
          {( emailLogin && !otLink ) && <>
            <Typography mt={1} mb={3} align="center">
              לצורך התחברות יש להזין את כתובת הדוא"ל איתו נרשמת למערכת
            </Typography>
            <TextField
              label="אימייל"
              value={email}
              name="email"
              onChange={(ev) => setEmail(ev.target.value)}
              sx={{ mb: 2 }}
              autoComplete="email"
              fullWidth
            />
            <TextField
              label="סיסמא"
              name="password"
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />

            <Link to="/forgot-password" style={{ alignSelf: "flex-start" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  mt: 0.8,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                שכחתי סיסמא
              </Typography>
            </Link>
            {serverError && <Typography color="error" height="1.5em">{serverError}</Typography>}

            <LoadingButton
              onClick={(ev) => loginHandler(ev, false)}
              type="submit"
              loading={loading}
              sx={{ mb: 1 }}
              fullWidth
              variant="contained"
            >
              התחבר
            </LoadingButton>

            <LoadingButton
              onClick={(ev) => otpEmail(ev)}
              type="submit"
              loading={loading}
              sx={{ mb: 1 }}
              fullWidth
              color="appBlack"
              variant="contained"
            >
              שלחו לי סיסמא חד פעמית למייל
            </LoadingButton>
          </> }


          {(!emailLogin && !smsLogin ) && <>
            <Typography mt={1} mb={3} align="center">
              לצורך התחברות יש להזין את מספר הנייד איתו נרשמת למערכת
            </Typography>
            <TextField
              label="מספר טלפון"
              value={phone}
              name="phone"
              onChange={(ev) => setPhone(ev.target.value)}
              sx={{ mb: 2 }}
              autoComplete="phone"
              fullWidth
            />
            
            {serverError && <Typography color="error" height="1.5em">{serverError} </Typography> }
            
            <LoadingButton
              onClick={(ev) => sendCode(ev, false)}
              type="submit"
              loading={loading}
              sx={{ mb: 1 }}
              fullWidth
              variant="contained"
            >
              שלחו לי SMS
            </LoadingButton>
          </> }
          
          {(!emailLogin && smsLogin ) && <>
            <Typography mt={1} mb={3} align="center">
              יש להזין את הקוד שנשלח אליכם לטלפון
            </Typography>
            <TextField
              label="קוד"
              value={code}
              name="text"
              onChange={(ev) => setCode(ev.target.value)}
              sx={{ mb: 2 }}
              autoComplete="text"
              fullWidth
            />
            
            { serverError && <Typography color="error" height="1.5em">{serverError}</Typography> }

            <LoadingButton
              onClick={(ev) => smsHandler(ev, false)}
              type="submit"
              loading={loading}
              sx={{ mb: 1 }}
              fullWidth
              variant="contained"
            >
              התחברות
            </LoadingButton>
          </> }
          
          <div id="recaptcha-container"></div>
          <Typography variant="text1" fontWeight="500" sx={{cursor: "pointer"}} onClick={() => {setEmailLogin(!emailLogin)}}>
            התחברות עם {!emailLogin ? "שם משתמש וסיסמא" : "SMS"}
          </Typography>
          
        </Stack>
      </Box>
    </Box>
  );
}

export default LoginPage;
