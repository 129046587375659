import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Card ,Button,MenuItem,TextField,Select} from "@mui/material";
import { Container } from "@mui/system";
import { UserContext } from "../../hooks/useUser";
import LoadingScreen from "../../components/LoadingScreen";
import { appFetch } from "../../utils/fetch";
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from "@mui/icons-material/Save";

const ReportsPage = () => {
  const { operator ,mosads} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState('אנא המתן בזמן שהמערכת מאתרת נתונים');
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState([]);
  const [confirmTotal, setConfirmTotal] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  const [editingRows, setEditingRows] = useState(new Set());  // שמירת מצב העריכה
  const [editValues, setEditValues] = useState({}); // שמירת הערכים לעריכה
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const res = await appFetch(`api/zohoOperators/reportsByOperator/${operator.Name}`);
        if (!res.ok) {
          throw new Error('Failed to fetch reports');
        }
        const data = await res.json();
        setReports(data.data);
        console.log(data.data)
        let totalSum = data.data.reduce((sum, report) => sum + (report.Total || 0), 0);
        setTotal(totalSum);
        totalSum = data.data.reduce((sum, report) => sum + (report.amount_confirm_activity || 0), 0);
        setConfirmTotal(totalSum);
        
        if (data.data.length === 0) {
          setNoData('לא נמצאו דיווחים');
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
        setNoData('שגיאה בהבאת נתונים');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [operator]);


    const getSessions = async (report) => {
      try {
        const res = await appFetch(`api/zohoOperators/sessionsByReport/${report.id}`);
        if (!res.ok) {
          throw new Error('Failed to fetch sessions');
        }
        const data = await res.json();
        setSessions(data.data);
        console.log(data.data)
        
        if (data===""||data.data.length === 0) {
          setNoData('לא נמצאו הפעלות');
        }
      } catch (error) {
        console.error("Error fetching sessions:", error);
        setNoData('שגיאה בהבאת נתונים');
      }
    };



  const columns = [
    {
      field: "Name",
      headerName: "מספר דיווח",
      minWidth: 130,
    },
    {
        field: "Last_Activity_Time",
        headerName: "תאריך",
        width: 150,
        valueFormatter: ({ value }) => value ? value.split('T')[0] : '', // חותך את התאריך אחרי ה-T
      },
      {
        field: "Reporting_status",
        headerName: "סטטוס דיווח",
        width: 250,
        renderCell: (params) => {
          const status = params.value;
          let color = '';
  
          switch (status) {
            case "חדש":
              color = "yellow"; // צבע צהוב
              break;
            case "ממתין לאישור":
              color = "pink"; // צבע ורוד
              break;
            case "אושר ממתין לתשלום":
              color = "pink"; // צבע ורוד
              break;
            case "שולם ממתין לחשבונית":
              color = "green"; // צבע ירוק
              break;
            case "התקבלה חשבונית":
              color = "green"; // צבע ירוק
              break;
            case "מבוטל":
              color = "black"; // צבע שחור
              break;
            default:
              color = "grey"; // צבע אפור ברירת מחדל
              break;
          }
  
          return (
            <Typography sx={{ color: color }}>
              {status}
            </Typography>
          );
        }
      },
    {
        field: "Total",
        headerName: "סכום דיווח",
        width: 150,
      },
      {
        field: "amount_confirm_activity",
        headerName: "סכום שאושר לתשלום",
        width: 150,
      },
      {
        field: "count_Activity",
        headerName: "מספר הפעלות",
        width: 150,
      },
    // ניתן להוסיף עמודות נוספות בהתאם לצורך
  ];

  const sessionColumns = [
    {
        field: "Mosad.name", // Accessing name inside Mosad
        headerName: "עבור",
        minWidth: 250,
        valueGetter: (params) => params.row.Mosad ? params.row.Mosad.name : 'לא זמין', // Fallback in case Mosad is missing
      },
    {
        field: "amount_to_operator",
        headerName: "סכום למפעיל",
        minWidth: 130,
      },
      {
        field: "TypeActivity",
        headerName: "סוג פעילות",
        minWidth: 130,
      },
      {
        field: "Reportingstatus",
        headerName: "סטטוס תשלום",
        minWidth: 130,
        renderCell: (params) => {
            const status = params.value;
            let color = '';
    
            switch (status) {
              case "חדש":
                color = "yellow"; // צבע צהוב
                break;
                case "אושר על ידי הגננת":
                color = "pink"; // צבע ורוד
                break;
                case "נדחה על ידי הגננת":
                color = "pink"; // צבע ורוד
                break;
                case "נדחה חוסר תקציב":
                color = "green"; // צבע ירוק
                break;
                case "בוצע תשלום":
                color = "green"; // צבע ירוק
                break;
                case "שולם ללא אישור גננת":
                color = "black"; // צבע שחור
                break;
                case "מבוטל":
                color = "black"; // צבע שחור
                break;
              default:
                color = "grey"; // צבע אפור ברירת מחדל
                break;
            }
    
            return (
              <Typography sx={{ color: color }}>
                {status}
              </Typography>
            );
          }
      },
    {
        field: "Date_of_activation",
        headerName: "תאריך",
        width: 150,
        valueFormatter: ({ value }) => value ? value.split('T')[0] : '', // חותך את התאריך אחרי ה-T
      },
      {
        field: "edit",
        headerName: "עריכה",
        width: 150,
        renderCell: (params) => {
          const isEditing = editingRows.has(params.row.id); // בודק אם השורה במצב עריכה, ראה שורה 260
          const canEdit = params.row.Reportingstatus === "חדש" || params.row.Reportingstatus === "נדחה על ידי הגננת"; 
          //const canEdit = params.row.Reportingstatus === "אושר על ידי הגננת";//זה שורה לטסטים , צריך לשנות את זה !!

          return canEdit ? ( // מציג את הכפתור רק אם הסטטוס מתאים
            <Button
              variant="contained"
              color={isEditing ? "secondary" : "primary"}
              onClick={() => handleEditToggle(params.row.id)}
            >
              {isEditing ? "בטל עריכה" : "לחץ לעריכה"}
            </Button>
          ) : "אין אפשרות לעריכה"
        },
      },      
      {
        field: "save",
        headerName: "",
        width: 150,
        renderCell: (params) => {
          const isEditing = editingRows.has(params.row.id); // בודק אם השורה במצב עריכה
          return (
            isEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveClick(params.row.id)}
              >
                שמור
              </Button>
            )
          );
        }
      }
    ];


   // פונקציה להפעלת עריכה בשורה
   const handleEditToggle = (id) => {
    const newEditingRows = new Set(editingRows);
    if (newEditingRows.has(id)) {
      newEditingRows.delete(id); // אם השורה כבר בעריכה, נסגור את העריכה
    } else {
      newEditingRows.add(id); // אחרת, נפתח את העריכה
    }
    setEditingRows(newEditingRows); // כאן יש הכנס לEditingRows ולפי זה ייקבע הערך בשורה 216
  };

  // פונקציה לשליחת השינויים לשרת
  const handleSaveClick = async (id) => {
    const updatedRow = editValues[id]; // הערכים המעודכנים לעריכה
  
    // בדיקה אם updatedRow קיים
    if (!updatedRow) {
      alert("לא נמצאו ערכים מעודכנים.");
      return;
    }
  
    try {
      const response = await appFetch("api/zohoOperators/updateSessionFields", {
        method: "POST",
        body: JSON.stringify({ id, ...updatedRow }), // שולח את כל הערכים המעודכנים
      });
  
      if (!response.ok) {
        throw new Error("Failed to update row");
      }
  
      alert("השינויים עודכנו בהצלחה");
      setEditingRows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id); // סגירת מצב העריכה
        return newSet;
      });
    } catch (error) {
      console.error("Error:", error);
      alert("שגיאה בעדכון השורה");
    }
  };
  
  // פונקציה לעדכון הערך בזמן עריכה
  const handleInputChange = (id, field, value) => {
    setSessions((prevSessions) =>
      prevSessions.map((session) =>
        session.id === id
          ? {
              ...session,
              ...(field === "Mosad.name"
                ? {
                    Mosad: {
                      name: value,
                      id: mosads.find((mosad) => mosad.Name === value)?.id || null,
                    },
                  }
                : { [field]: value }),
            }
          : session
      )
    );
  
    setEditValues((prevValues) => ({
      ...prevValues,
      [id]: {
        ...prevValues[id],
        ...(field === "Mosad.name"
          ? {
              Mosad: {
                name: value,
                id: mosads.find((mosad) => mosad.Name === value)?.id || null,
              },
            }
          : { [field]: value }),
      },
    }));
  };
  

  const renderEditableCell = (params) => {
    const isEditing = editingRows.has(params.row.id);
    const value = editValues[params.row.id]?.[params.field] ?? params.value;
  
    // אם זה השדה Mosad.name, הצג Select
    if (isEditing && params.field === "Mosad.name") {
      return (
        <Select
          value={value}
          onChange={(e) =>
            handleInputChange(params.row.id, params.field, e.target.value)
          }
          variant="standard"
          size="small"
          fullWidth
        >
          {mosads.map((mosad) => (
            <MenuItem key={mosad.id} value={mosad.Name}>
              {mosad.Name}
            </MenuItem>
          ))}
        </Select>
      );
    }
  
    // עבור שדות אחרים במצב עריכה, השתמש ב-TextField
    return isEditing ? (
      <TextField
        value={value}
        onChange={(e) =>
          handleInputChange(params.row.id, params.field, e.target.value)
        }
        variant="standard"
        size="small"
        fullWidth
      />
    ) : (
      params.value
    );
  };
  
  // עדכון העמודות
  const updatedColumns = sessionColumns.map((col) =>
    col.field === "Mosad.name" || col.field === "amount_to_operator" || col.field === "Date_of_activation"
      ? {
          ...col,
          renderCell: renderEditableCell
        }
      : col
  );
  
  

  const groupedReportsByStatus = {
    חדש: [], 
    "ממתין לאישור": [],
    "אושר ממתין לתשלום": [],
    "שולם ממתין לחשבונית": [],
    "התקבלה חשבונית": [],
    מבוטל: [],
  };

  reports?.forEach((item) => {
    const Reporting_status = item.Reporting_status;
    if (!groupedReportsByStatus[Reporting_status]) {
      groupedReportsByStatus[Reporting_status] = []; // אתחול מערך אם לא קיים
    }
    groupedReportsByStatus[Reporting_status].push(item); // הוספת הפריט
  });
  

  return (
    <Container sx={{ height: '100vh' }}>
      <Typography mb={2} variant="h3" color="appPink.main">
        רשימת דיווחים
      </Typography>
<Box
        display={"flex"}
        sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
        justifyContent="center"
        gap={1.5}
      >
        <InsightCard
          color={"appYellow.main"}
          label="חדש"
         // value={myChildren.length}
          value={groupedReportsByStatus["חדש"].length}
        />
                    <InsightCard
                color={"appPink.main"}
                label="ממתין לאישור"
                value={groupedReportsByStatus["ממתין לאישור"].length} 
            />
        <InsightCard
          color={"appPink.main"}
          label="אושר ממתין לתשלום"
         // value={myChildren.length}
          value={groupedReportsByStatus["אושר ממתין לתשלום"].length}
        />
        <InsightCard
          color={"appGreen.main"}
          label="שולם ממתין לחשבונית"
         // value={myChildren.length}
          value={groupedReportsByStatus["שולם ממתין לחשבונית"].length}
        />
        <InsightCard
                color={"appGreen.main"}
                label="התקבלה חשבונית"
                value={groupedReportsByStatus["התקבלה חשבונית"].length}  
            />
            <InsightCard
                color={"appBlack.main"}
                label="מבוטל"
                value={groupedReportsByStatus["מבוטל"].length}  
            />
                        <InsightCard
                color={"appRed.main"}
                label="סכום דיווחים"
                value={total}  
            />
             <InsightCard
                color={"appRed.main"}
                label="סכום שאושר"
                value={confirmTotal}  
            />
            
      </Box>

      {loading ? (
        <LoadingScreen />
      ) : reports.length > 0 ? (
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={reports}
            columns={columns}
            getRowId={(row) => row.id} // מקבל את מזהה השורה
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            onRowClick={(params) => {
              setExpandedRow(expandedRow === params.row.id ? null : params.row.id); // פתיחה או סגירה של השורה
              getSessions(params.row); // קריאה לפונקציה כדי להביא את ההפעלות
            }}
          />

          {reports.map((report) => {
            const isExpanded = expandedRow === report.id; // בודק אם השורה הזו פתוחה
            //const sessionsData = sessions[report.id] || []; // מקבל את ההפעלות לשורה זו

            return isExpanded ? (
                <Accordion 
  key={report.id}
  onChange={(event, isExpanded) => setExpanded(isExpanded)}
>
  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    <Typography>
      {expanded 
        ? `הפעלות עבור דיווח ${report.Name}` 
        : `ניתן ללחוץ על השורה כדי לצפות בהפעלות עבור דיווח ${report.Name}`}
    </Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Box sx={{ height: 300, width: '100%' }}>
      {/* בדיקה אם sessionsData הוא מערך וכולל נתונים */}
      {Array.isArray(sessions) && sessions.length > 0 ? (
        <DataGrid
          rows={sessions} // משתמש ב-sessionsData כאן
          columns={updatedColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      ) : (
        <Typography variant="body1" color="textSecondary">
          אין הפעלות זמינות
        </Typography> // מראה הודעה אם אין נתונים
      )}
    </Box>
  </AccordionDetails>
</Accordion>

              
            ) : null;
          })}
        </Box>
      ) : (
        <Typography>{noData}</Typography>
      )}
    </Container>
  );
};

export default ReportsPage;
export function InsightCard({ value, color, label }) {
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "6rem",
          textAlign: "center",
          width: { xs: "30%", md: "19%" },
          padding: { xs: "10px" },
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: "1.4rem", md: "2.5rem" } }}
          color={color}
        >
          {value}
        </Typography>
        <Typography
          margin={"5px"}
          sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
          variant="h4"
        >
          {label}
        </Typography>
      </Card>
    );
  }
