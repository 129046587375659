import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import AdminLayout from "../layout/AdminLayout";
import AuthorityLayout from "../layout/AuthorityLayout";
import Layout from "../layout/Layout";
import CoordinatorLayout from "../layout/CoordinatorLayout";
import OperatorLayout from "../layout/OperatorLayout";

const ProtectedRoute = ({ role = 1 }) => {
  const { user } = useUser();
  if (user && user.role === role) {
    console.log(user.role)
  //  const LayoutComponent = user.role === 0 ? AdminLayout : Layout;

   const LayoutComponent = 
   user.role === 0 
     ? AdminLayout 
     : user.role === 1 
     ? Layout 
     : user.role === 2 
     ? AuthorityLayout 
     : user.role === 3 
     ? CoordinatorLayout 
     : user.role === 4 
     ? OperatorLayout 
     : Layout;
 


    return (
      <LayoutComponent>
        <Outlet />
      </LayoutComponent>
    );
  }

  return <Navigate to="/" replace />;
};

export default ProtectedRoute;
