import { Box, Card, CardContent, Container, Divider, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hooks/useUser";
import appConfig, { numberWithCommas } from "../utils/appConfig";
import { DataGrid } from "@mui/x-data-grid";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function BudgetsPage() {

    const { mosad } = useContext(UserContext);
    const [ data, setData ] = useState({});
    const [ err, setErr ] = useState();
    const [ budgetsList, setBudgetsList ] = useState( !appConfig.is_mobile );

    useEffect( () => {
        if( mosad ){
            fetch(appConfig.apiUrls.budgetsData + mosad.field1 )
            .then((res) => res.json())
            .then( data => setData(data))
            .catch(err => setErr("אין נתונים להצגה"));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mosad])

    const metaDataItems = [
        { label: "עיר", key: "field" },
        { label: "שם הגננת", key: "field5" },
        { label: "אימייל גננת", key: "Email" },
        { label: "טלפון גננת", key: "field6" },
        { label: "סמל מוסד", key: "field1" },
      ];
      console.log(metaDataItems)
    
    const paymentDataItems = [
        { label: "טיולים", key: "amount_trip", val : 0 },
        { label: "מסיבת סיום", key: "amount_party", val : 0 },
        { label: "תל״ן", key: "amount_talan", val : 0 },
        { label: "ביטוח", key: "amount_bitoch", val : 0 },
        { label: "סל תרבות", key: "amount_tarbot", val : 0 },
        { label: "גן מדע", key: "amount_gam_mada", val : 0 },
        { label: "גן מורשת", key: "field12", val : 0 },
        { label: "מזון", key: "amount_food", val : 0 },
        { label: "שירותים ורכישות", key: "amount_services", val : 0 },
    ];
    console.log(paymentDataItems)
    const paymentDataItemsRows = [];

    if( mosad ){
        paymentDataItems.forEach( (item, i)  => {
            if( mosad[item.key] ){
                item.val = mosad[item.key];
                paymentDataItemsRows.push(item)
            }
        });
    }    
    
    const budgets = Object.values(data).filter( item => typeof item === "object" && (item.get !== "0" || item.use !== "0" || item.left !== "0"))
  .map(item => {
                if (mosad.field13.id !== "5154700000024946086") {// עבור עיריית לוד
                    // התנהגות רגילה בהתאם לשמות
                    switch (item.name) {
                    case "תקציב עירייה":
                    case "שירותים ורכישות":
                        item.useWord = "הועבר לכרטיס";
                        break;
                    case "ביטוח":
                        item.useWord = "הועבר למועצה";
                        break;
                    default:
                        item.useWord = "שומש";
                        break;
                    }
                } else {
                    // אם field13.id שווה ל-"5154700000024946086"
                    if (item.name === "ביטוח") {
                    item.useWord = "הועבר לעירייה";
                    } else {
                    item.useWord = "הועבר לכרטיס";
                    }
                }         
                return item;
            });
    const budgetSum = budgets.reduce((sum, b) => sum +=  parseFloat(b?.get.replace(/,/g, '')), 0)

    const columns = [
    {
        headerName: "עבור",
        field: "label",
        width: 150
    },
    {
        headerName: "סכום",
        field: "val"
    },
    ];

    return (
        <Container className="BudgetsPage">
            <Typography mt={10} mb={2} variant="h2" color="appPink.main">
                גן { mosad?.Name }
            </Typography>
            <Grid container spacing={3} mb={3}>
                {mosad && metaDataItems.map((i) => (
                    <Grid item key={i.key} xs={12} lg={2} mt={0} className="mobile-gap">
                        <div className="mobile-flex"><Typography fontWeight={800}>{i.label}</Typography><Typography>{mosad[i.key]}</Typography></div>
                    </Grid>
                ))}
            </Grid>

            <Divider />
            { ( data && !err ) ? <Box>
                <Grid container mt={1} spacing={5} >
                    
                    <Grid item xs={12} lg={8}>
                    <Typography variant="h3" mb={2}>
                            ס"ה נגבו: {numberWithCommas(budgetSum)} ₪
                        </Typography>

                        {/* <Typography variant="h5" mb={2}>
                            {loading ? (
                                "מעדכן נתונים עבור כרטיס נטען "  // הטקסט יוצג כאשר loading הוא true

                            ) : upayCardBalance !== undefined && !isNaN(upayCardBalance) ? (

                                <>יתרה בכרטיס נטען: {numberWithCommas(upayCardBalance)} ₪
                                <Typography variant="h5" mb={2}>
                                נכון לתאריך: {lastBalanceDate}
                            </Typography>
                            </>
                            ) : (
                                "כרטיס נטען: אין נתוני יתרה זמינים"
                            )}
                        </Typography> */}
                        <Grid container spacing={3}>
                            { budgets.map(item => (                         
                                <Grid item key={item.name} xs={12}  lg={4}>
                                    <Card sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        width: "100%"
                                    }}>
                                        <CardContent sx={{ width: "100%"}}>
                                            <Typography variant="p"
                                                sx={{ fontSize: { xs: "1rem", md: "1.5rem" }, fontWeight: 800 }}
                                                color="appPink.main">{item?.name}
                                            </Typography>

                                            <div className='big'>{item?.left} ₪ <span>נותר</span></div>
                                            {/* <progress max={parseFloat(item?.get?.replace(/,/g, '')) || 100 } value={parseFloat(item?.use?.replace(/,/g, '')) || 0}></progress> */}
                                            <div className="progress-line">
                                                <div style={{width: ((parseFloat(item?.use.replace(/,/g, '')) / parseFloat(item?.get.replace(/,/g, ''))) * 100) + "%" }}></div>
                                            </div>
                                            <div className="wrapper-details">
                                                <span className="use-span"> {item?.useWord} {item?.use} </span>
                                                <span className="in-span">מתוך </span>{item?.get} ₪ <span className="in-span">שנגבו</span>
                                            </div>
                                        </CardContent>
                                    </Card>

                                </ Grid>
                            ))} 
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Typography mb={2} className="btn-mobile" onClick={() => setBudgetsList( true ) }>רשימת סעיפי גבייה ועלויות {appConfig.is_mobile && <KeyboardArrowDownIcon />}</Typography>
                        {budgetsList && <Box  sx={{ height: "50vh", overflow: "auto", maxWidth: "100%" }}>
                        <DataGrid
                            columns={columns}
                            rows={paymentDataItemsRows}
                            getRowId={ r => r.key }
                        />
                        </Box> }
                    </Grid>
                </Grid>
            </Box> : <Box minHeight={"300px"} sx={{display:"flex", alignItems: "center", justifyContent: "center"}}><Typography color="red" fontSize={30}>{err}</Typography></Box>}

        </Container>
    );
}

export default BudgetsPage;
