import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";

import { useForm } from "react-hook-form";
import { appFetch } from "../../utils/fetch";
import HookFormInput from "../HookFormInput";
import { useSnackbar } from "notistack";

function CreateCardDialog({ open, onClose, onSuccess, onError }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      number: "",
    },
  });
  const [issueDate, setIssueDate] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const res = await appFetch(`/api/card`, {
        method: "POST",
        body: JSON.stringify({ ...formData, issueDate }),
      });
      if (!res.ok) throw new Error();
      enqueueSnackbar("כרטיס חדש נוצר");
      onSuccess();
      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("אירעה שגיאה", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  // const onSubmit = async (formData) => {
  //   try {
  //     setLoading(true);
  //     const res = await appFetch(`/api/card/upayCard`, {
  //       method: "POST",
  //       body: JSON.stringify({ ...formData, issueDate }),
  //     });
  //     if (!res.ok) throw new Error();
  //     enqueueSnackbar("כרטיס חדש נוצר");
  //     onSuccess();
  //     onClose();
  //   } catch (err) {
  //     console.error(err);
  //     enqueueSnackbar("אירעה שגיאה", { variant: "error" });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            color={"white"}
            component="div"
          >
            יצירת כרטיס
          </Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack p={3} spacing={1.5} display="flex" alignItems="center">
          <HookFormInput
            control={control}
            errors={errors}
            label="מספר כרטיס"
            fieldKey="number"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              maxDate={new Date()}
              name={"issueDate"}
              value={issueDate}
              label="תאריך הנפקה"
              onChange={(dayjsDate) => {
                setIssueDate(dayjsDate.toDate());
              }}
              renderInput={(params) => (
                <TextField required sx={{ minWidth: 300 }} {...params} />
              )}
            />
          </LocalizationProvider>
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            sx={{ mt: 3 }}
          >
            צור כרטיס
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

export default CreateCardDialog;
