import { Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hooks/useUser";
import { appFetch } from "../utils/fetch";

function CardLoadingPage() {
  const { mosad,loadCardData } = useContext(UserContext);
  //const [load, setLoad] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await appFetch(`api/zohoPayments/operationsByMosadID/${mosad.ID}`, {
  //         method: 'GET',
  //       });
  //       if (!res.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await res.json();
        
  //       // Filter data for loading card data
  //       const loadedData = data.reduce((acc, item) => {
  //         if (item.operatorData[0]?.field2 === 'הטענת כרטיסים') {
  //           acc.loadCardData.push(item);
  //         }
  //         return acc;
  //       }, { loadCardData: [] });
        
  //       // Set state with the load card data
  //       setLoadCardData(loadedData.loadCardData);
  //       console.log(loadedData.loadCardData);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoad(false);
  //     }
  //   })();
  // }, [mosad.Name]);

  const getColumns = () => {
    return [
      {
        field: 'date',
        headerName: 'תאריך',
        width: 200,
        valueGetter: (params) => params.row.results[0]?.Date_pay,
      },
      {
        field: 'amount',
        headerName: 'סכום',
        width: 120,
        renderCell: (params) => {
          const totalAmount = params.row.results.reduce((sum, result) => sum + (result.AmountToCalculate || 0), 0);
          return `₪${totalAmount}`;
        },
      },
      
    ];
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const getDetailColumns = () => {
    return [
      {
        field: 'TypeActivity',
        headerName: 'סוג ההפעלה',
        width: 130,
      },
      {
        field: 'AmountToCalculate',
        headerName: 'סכום',
        width: 100,
        renderCell: (params) => `₪${params.value}`,
      },
    ];
  };

  return (
    <Container sx={{ width: "100%" }}>
      <div style={{ display: 'flex', alignItems: 'right', flexDirection: 'column'  }}>
      <Typography mt={2} mb={2} variant="h3" color="appPink.main">
        הטענות בכרטיס
      </Typography>    
       <Typography mt={0} mb={0} variant="h6" color="appPink.main">
       ניתן ללחץ על השורה לקבלת פירוט על ההעברה 
       </Typography>    
       
      </div>
      <DataGrid
        autoHeight
        rows={loadCardData || []}
        columns={getColumns()}
        //loading={load}
        headerHeight={50}
        getRowId={(row) => row.operatorData[0]?.id}
        onRowClick={handleRowClick}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>
          פירוט סעיפי טעינת כרטיס בתאריך {new Date(selectedRow?.results[0]?.Date_pay).toLocaleDateString("he")}
        </DialogTitle>
        <DialogContent>
          <DataGrid
            autoHeight
            rows={selectedRow?.results || []}
            columns={getDetailColumns()}
            headerHeight={50}
            getRowId={(row) => row.id}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>סגור</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CardLoadingPage;
