import React from 'react';
import { Box,Card, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, MenuItem, Menu, TextField, FormHelperText } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import appConfig from "../utils/appConfig";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../hooks/useUser";
import useSWR from "swr";
import { swrFetcher } from "../utils/fetch";
import { appFetch } from "../utils/fetch";

function StudentPage() {
  
  const { mosad, user } = useContext(UserContext);
  
  const mosad_name = user.mosad_name || "";
  //const url = appConfig.apiUrls.studentData + mosad.ID;

  const [data, setData ] = useState([]);
  const [err, setErr ] = useState(null);
  const [load, setLoad ] = useState(true);
  const [dialogOpen, setDialogOpen ] = useState(false);
  const [dialogText, setDialogText ] = useState("");
  const [onConfirmFunc, setOnConfirmFunc] = useState(null);

  //אנחנו מביאים את המידע מהזוהו ביוז אפקט הבא. השארתי את זה כאן כהיסטוריה..
//   useEffect(() => {
//     fetch(url)
//     .then((res) => res.json())
//     .then((d) => setData(d))
//     .catch((err) => setErr(err))
//     .finally(() => setLoad(false));
    
// }, [url, onConfirmFunc]); 

useEffect(() => {
  (async () => {
    try {
      if (!mosad) {
        return; 
      }
      const res = await appFetch(`api/zohoMosads/SchedulingByMosadID/${mosad.id}`, {
        method: 'GET',
      });
      if (!res.ok) {
        throw new Error('Failed to fetch students');
      }
      const data = await res.json();
      setData(data.data);
      console.log(data.data)
    } catch (error) {
      console.error('Error fetching students:', error);
    }
    finally {
      setLoad(false);
    }
  })();
}, [ onConfirmFunc]);

  const resetFn = () => {
    setDialogOpen(false);
    setDialogText("")
    setOnConfirmFunc(null)
  }

  const reminder = (id, e) => {
    fetch(appConfig.apiUrls.studentReminder + id)
      .catch((err) => alert(err.message))
      .finally(() => resetFn());
  };

  // פונקציה לפתיחת הדיאלוג עם הודעה
  const handleOpenDialogWithMessage = (message) => {
    setDialogText(message);
    setDialogOpen(true);
  };

  // פונקציה לסגירת הדיאלוג
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogText('');
  };

  const cancel_register = async (data, e) => {
    try {
      const updateResponse = await appFetch(`api/zohoMosads/studentCancelRegister/${data.id}`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: 'דווח כשיבוץ לא נכון',
        })
      });
  
      if (!updateResponse.ok) {
        setErr("שינוי סטטוס נכשל, נסה שנית מאוחר יותר או פנה לשרות לקוחות");
        throw new Error(err);
      }
  
      console.log('Duplicate placement reported successfully');
      handleOpenDialogWithMessage("סטטוס התלמיד שונה בהצלחה");
  
    } catch (err) {
      alert(err.message); // הצגת הודעת השגיאה מתוך ה-message של השגיאה
      console.error("Error reporting duplicate placement:", err);
    } finally {
      resetFn(); // מתבצע בכל מקרה, גם אם הייתה שגיאה
    }
  };
  

  // const cancel_register = (data, e) => {
  //   fetch(`${appConfig.apiUrls.studentCancelRegister}?id=${data.id}&mosad_name=${mosad_name}&kid_name=${data.contactid.name}`)
  //     .catch((err) => alert(err.message))
  //     .finally(() => resetFn());
  // };

  const send_student_details = (e) => {
    e.preventDefault(); 
    const name = e.target[0].value;
    const lastName = e.target[2].value;
    const id = e.target[4].value;
  
    fetch(`${appConfig.apiUrls.notInTheListStudentData}?id=${id}&user_ID=${user.organization.organizationId}&mosad_ID=${mosad.id}&kid_name=${name}&kid_last_name=${lastName}`)
      .then(response => {
        if (response.ok) {
          const successMessage = document.createElement('div');
          successMessage.textContent = 'הטופס נשלח בהצלחה!';
          successMessage.style.cssText = 'position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; background-color: lightgreen; border: 2px solid green; border-radius: 5px; z-index: 1000;';
          document.body.appendChild(successMessage);
          setTimeout(() => {
            successMessage.remove();
          }, 3000);
        } else {
          alert('שגיאה בשליחת הטופס.');
        }
      })
      .catch(err => {
        console.error('Error:', err);
        alert('שגיאה בשליחת הטופס.');
      })
      .finally(() => closeStudentPopup());
  };
  
  
  

  function openAlertDialogHandler(type, onConfirm, selectedItem) {
    setOnConfirmFunc(() => onConfirm);
    if( type === "cancel" ){
      if(buttonMessage==='לא נמצא בגן'){
        setDialogText(`האם את בטוחה שאת רוצה להסיר את הילד ${selectedItem?.contactid?.name} מהרישום לגן?`);
      }
      if(buttonMessage==='לא נמצא ברשימה'){
        setDialogText(`האם את בטוחה שהילד ${selectedItem?.contactid?.name} לא נמצא ברשימה?`);
      }
    }
    if( type === "reminder" ){
      setDialogText(`האם לשלוח תזכורת על חוב ל - ${selectedItem?.contactid?.name}?`);
    }
    setDialogOpen(true);
  }

  
  const columns = [
    {
      headerName: "",
      field: "c",
      width: 20,
    },
    {
      headerName: "שם",
      field: "contactid",
      width: 120,
      valueGetter: ({ value }) => {
        return value.name.length > 15 ?"..."+ value.name.substring(0, 12)   : value.name;
      },
    },
    {
      headerName: "סטטוס",
      width: 100,
      field: "status",
      renderCell: (params) => {
        let color = 'red';
        switch (true) {
          // case (params.value === 'דווח כשיבוץ לא נכון'): // Add curly braces
          //   color = 'red';
          //   break;
          case (params.value === 'פעיל - שילם הכל' || params.row.debt < 0): // Add curly braces
            color = 'green';
            break;
          case (params.row.DirectDebit === "הוראת קבע פעילה"): // Add curly braces
              color = 'blue';
              break;
          case (params.value === 'פעיל- יש חוב' && params.row.payed !== 0): // Add parentheses
            color = 'orange';
            break;
          default:
            color = 'red';
            break;
        }
        
    
        const valueGetter = (value) => {
          if (value === 'פעיל - שילם הכל') {
            return "שילם הכל";
          }
          if (value === 'פעיל- יש חוב'&&params.row.DirectDebit !== "הוראת קבע פעילה") {
            return "קיים חוב";
          }
          if (value === 'פעיל- יש חוב'&&params.row.DirectDebit === "הוראת קבע פעילה") {
            return "הו''ק פעילה";
          }
          if (value === 'פעיל - ממתין לזיכוי') {
            return "ממתין לזיכוי";
          }
          // if (value === 'דווח כשיבוץ לא נכון') {
          //   return "שיבוץ לא נכון";
          // }
          // Return the original value if no match is found
          return value;
        };
    
        return (
          <div style={{ color }}>
            {valueGetter(params.value)}
          </div>
        );
      }
    },
    {
      headerName: "סכום ששולם",
      width: 80,
      field: "payed",
      renderCell: ({ value }) => value ? 
         <> 
           &#8362;
          <span style={{ marginLeft: '3px' }}>{parseInt(value).toLocaleString()}</span>
           
         </>
      : 0,
    },
  ];

  const reminderPopupColumns = [
    {
      headerName: "",
      field: "c",
      width: 20,
    },
    {
      headerName: "שם",
      field: "contactid",
      width: 120,
      valueGetter: ({ value }) => {
        return value.name.length > 15 ?"..."+ value.name.substring(0, 12)   : value.name;
      },
    },
    
    {
      headerName: "פעולה",
      width: 160,
      field: "actions",
      //type: "actions",
      renderCell: (params) => (
        <div style={{textAlign: "right", width: "100%"}}>
          {  params?.row?.status === "פעיל- יש חוב" && ( 
          <Button  variant="contained" sx={{marginLeft: 1, minWidth: 150}}
            onClick={(e) => openAlertDialogHandler('reminder', () => reminder(params?.row?.contactid?.id, e), params?.row)}>
              { "שלח תזכורת" }
            </Button > )
          }

        </div>
      ),
    },
  ];

  const [buttonMessage, setButtonMessage ] = useState(null);

  const reportPopupColumns = [
    {
      headerName: "",
      field: "c",
      width: 10,
    },
    {
      headerName: "שם",
      field: "contactid",
      width: 120,
      valueGetter: ({ value }) => {
        return value.name.length > 15 ?"..."+ value.name.substring(0, 12)   : value.name;
      },
    },
    
    {
      headerName: "פעולה",
      width: 160,
      field: "actions",
      //type: "actions",
      renderCell: (params) => (
        <div style={{textAlign: "right", width: "100%"}}>
          {(buttonMessage==='לא נמצא בגן') ? (
            <Button 
              color="error"
              variant="contained"
              onClick={(e) => openAlertDialogHandler('cancel' , () => cancel_register(params?.row, e), params?.row)}
            >
              {buttonMessage}
            </Button>
          ) : (
            <Button 
              variant="contained"
              onClick={(e) => openAlertDialogHandler('cancel' , () => send_student_details(params?.row, e), params?.row)}
            >
              {buttonMessage}
            </Button>
          )}
        </div>
      ),
    }    
  ];
 

const [data_01, setData_01] = useState([]);

useEffect(() => {
  setData_01(data
    .filter(item => item.status !== "דווח כשיבוץ לא נכון") // מסנן את התלמידים
    .map((i, k) => ({...i, c: k + 1})) // מוסיף אינדקס סידורי
  );
}, [data]);

const data_02 = data
  .filter(item => item.status === "פעיל- יש חוב" && item.DirectDebit !== "הוראת קבע פעילה")
  .map((i, k) => ({ ...i, c: k + 1 }));

//const data_03 = data.filter(item => item.status !== "דווח כשיבוץ לא נכון").map((i, k) => ({ ...i, c: k + 1 }));

  // const { data: myChildren, mutate } = useSWR(url,
  //   swrFetcher
  // );

const myChildren=data

  // let totalfees = 0;
  const feesChildren = [];
  const groupedChildrenByStatus = {
    "פעיל - שילם הכל": [],
    "פעיל- יש חוב": [],
    "פעיל - ממתין לזיכוי": [],
    "דווח כשיבוץ לא נכון": [],
    "פעיל - עזיבה": [],
    "פעיל ממתין לעדכון סכומי גביה": [],
    "פעיל משלם דרך הישוב": [],
    "לא פעיל": [],
    "לא פעיל ממתין לזיכוי": [],
    "שיבוץ כפול": [],
    "ממתין לביטול": [],
  };
  myChildren?.forEach((item) => {
    if (item.typeCode === 722) {
      // totalfees += item.amount;
      feesChildren.push(item);
    } else {
      const childStatus = item.status;
      groupedChildrenByStatus[childStatus].push(item);
    }
  });

  //const filteredChildren = Object.values(groupedChildrenByStatus).slice(0, 3).reduce((acc, cur) => acc + cur.length, 0);

  var didNotPay=0;
  var partiallyPaid=0;
  var paidAll=0;
  var filteredChildren= 0;

  myChildren?.forEach((item) => {
     if (item.status === "פעיל- יש חוב" && item.payed !== 0) {
       partiallyPaid++;
    }if (item.status === "פעיל- יש חוב" && item.payed === 0) {
      didNotPay++;
     }
    if (item.status === "פעיל - שילם הכל" ||item.status === "פעיל - ממתין לזיכוי") {
      paidAll++;
     }
    if (item.status !=="דווח כשיבוץ לא נכון") {
      filteredChildren++;
     }
  });


  const [reminderShowPopup, setReminderShowPopup] = useState(false);
  const [reportShowPopup, setReportShowPopup] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState();
  const [studentDetailsDialogOpen, setStudentDetailsDialogOpen] = useState();

  const reminderTogglePopup = () => {
    setReminderShowPopup(!reminderShowPopup);
    setRemind(!remind);
    setReportButtonVisible(!reportButtonVisible);
    setFilterButtonVisible(!filterButtonVisible);
  };
  const reportTogglePopup = () => {
    setReport(!report);
    onReport==="עדכון שיבוץ"?
    setReportDialogOpen(!reportDialogOpen): 
    setReportShowPopup(!reportShowPopup);
    setRemindButtonVisible(!remindButtonVisible);
    setFilterButtonVisible(!filterButtonVisible);
  };

  const reportTogglePopup1 = () => {
    setButtonMessage("לא נמצא בגן");
    setReportShowPopup(!reportShowPopup);
    setReportDialogOpen(false);
  };

  const reportTogglePopup2 = () => {
   // setButtonMessag("לא נמצא ברשימה");
   // setReport(!report);
    setReportDialogOpen(false);
    setStudentDetailsDialogOpen(true)
  };

  const closeStudentPopup = () => {
    setReport(!report);
    setRemindButtonVisible(!remindButtonVisible);
    setFilterButtonVisible(!filterButtonVisible);
    setStudentDetailsDialogOpen(false);
  }

  const [remind, setRemind] = useState(true);
  const [report, setReport] = useState(true);
  const [filter, setFilter] = useState(true);
  const onRemind =remind?"שלח תזכורת":"חזרה";
  const onReport =report?"עדכון שיבוץ":"חזרה";
  const onFilter =filter?"סנן":"בטל סינון";
  const [reportButtonVisible, setReportButtonVisible] = useState(true);
  const [remindButtonVisible, setRemindButtonVisible] = useState(true);
  const [filterButtonVisible, setFilterButtonVisible] = useState(true);

  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    if(onFilter==="בטל סינון"){
      setData_01(data.map((i, k) => ({ ...i, c: k + 1 })));
      setAnchorEl(null);
      setFilter(!filter);
      setRemindButtonVisible(!remindButtonVisible);
      setReportButtonVisible(!reportButtonVisible);
    }else{
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick1 = (event) => {
    //setData_01(data.filter(item => item.status !== "פעיל - שילם הכל" && item.DirectDebit===null).map((i, k) => ({ ...i, c: k + 1 })));
    setData_01(data.filter(item => item.status === "פעיל- יש חוב" && item.DirectDebit !=="הוראת קבע פעילה").map((i, k) => ({ ...i, c: k + 1 })));
    setAnchorEl(null);
    setFilter(!filter);
    setRemindButtonVisible(!remindButtonVisible);
    setReportButtonVisible(!reportButtonVisible);
  };

  const handleMenuItemClick2 = (event) => {
    setData_01(data.filter(item => item.status === "פעיל- יש חוב" && item.DirectDebit==="הוראת קבע פעילה").map((i, k) => ({ ...i, c: k + 1 })));
    setAnchorEl(null);
    setFilter(!filter);
    setRemindButtonVisible(!remindButtonVisible);
    setReportButtonVisible(!reportButtonVisible);
  };

  const handleMenuItemClick3 = (event) => {
    setData_01(data.filter(item => (item.status === "פעיל - שילם הכל"||item.status ==="פעיל - ממתין לזיכוי")).map((i, k) => ({ ...i, c: k + 1 })));
    setAnchorEl(null);
    setFilter(!filter);
    setRemindButtonVisible(!remindButtonVisible);
    setReportButtonVisible(!reportButtonVisible);
  };

  const onConfirmButton = ()=> {
    onConfirmFunc();
    if(reminderShowPopup&& !reportShowPopup){
      reminderTogglePopup();

    }
    if(!reminderShowPopup&& reportShowPopup){
      reportTogglePopup(); 
    }
  } 

  return (
    <Container>
<Box
        display={"flex"}
        sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
        justifyContent="center"
        gap={1.5}
      >
        <InsightCard
          color={"appPink.main"}
          label="ילדים בגן"
         // value={myChildren.length}
          value={filteredChildren}
        />
        <InsightCard
          color={"appGreen.main"}
          label="שילמו הכל"
          value={paidAll}
        />
        <InsightCard
          color={"appYellow.main"}
          label="שילמו חלקית"
          value={partiallyPaid}
        />
        <InsightCard
          color={"appBlack.main"}
          label="לא שילמו"
          value={didNotPay}
        />
      </Box>

      

      <Typography mt={2} mb={2} variant="h3" color="appPink.main">
      <div>
        פירוט תשלומים לפי תלמיד 
        </div>
        <div>
        {filterButtonVisible? (
        <Button variant="contained" sx={{margin: 1}} onClick={handleButtonClick}>{onFilter}</Button>
      ):null}  
        {remindButtonVisible ? (
      <Button variant="contained" sx={{margin: 1}} onClick={reminderTogglePopup} title="שליחת תזכורת להורה לשלם" >{onRemind}</Button>
      ) : null}
      {reportButtonVisible ? (
      <Button variant="contained" sx={{margin: 1}} onClick={reportTogglePopup} title="דווח על ילד שלא נמצא בגן" >{onReport}</Button>
      ) : null} 
      <Menu
        anchorEl={anchorEl}
        open={(Boolean(anchorEl)) && (onFilter==="סנן")}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleMenuItemClick1}>ילדים שיש להם חוב ללא הו"ק</MenuItem>
        <MenuItem onClick={handleMenuItemClick2}>ילדים שיש להם חוב וגם הו"ק</MenuItem>
        <MenuItem onClick={handleMenuItemClick3}>ילדים שאין להם חוב</MenuItem>
      </Menu>
    </div>
      </Typography>

      <DataGrid
        autoHeight
       // rows={(reminderShowPopup ? data_02 :data_01) || []}
        rows={(reminderShowPopup ? data_02 :data_01) || []}
        columns={reminderShowPopup ? reminderPopupColumns : reportShowPopup ? reportPopupColumns : columns}
        loading={ load }
      />

{dialogOpen !== undefined ? (
      <Dialog
        open={dialogOpen}
        onClose={resetFn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">שימי לב!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={resetFn}>ביטול</Button>
          <Button variant="contained" onClick={onConfirmButton} autoFocus>אישור</Button>
        </DialogActions>
      </Dialog>
     ) : null} 

{reportDialogOpen !== undefined ? (
      <Dialog
        open={reportDialogOpen}
        onClose={reportTogglePopup2 ||reportTogglePopup1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">מה ברצונך לדווח?</DialogTitle>
        <DialogActions>
          <Button color="error" variant="contained" onClick={reportTogglePopup1}>ילד ברשימה שלא נמצא בגן</Button>
          <Button variant="contained" onClick={reportTogglePopup2}>ילד מהגן שלא ברשימה</Button>
        </DialogActions>
      </Dialog>
      ) : null}

{studentDetailsDialogOpen !== undefined ? (
      <Dialog
      open={studentDetailsDialogOpen}
      onClose={closeStudentPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">נא להזין את פרטי הילד שאינו מופיע ברשימות</DialogTitle>
      <form onSubmit={(e) => send_student_details(e)}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="שם"
            type="text"
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="lastName"
            label="שם משפחה"
            type="text"
            fullWidth
            required
          />
          <TextField
            margin="dense"
            id="id"
            label="מספר תעודת זהות"
            type="text"
            fullWidth
            required
            inputProps={{
              pattern: "[0-9]*" // רק ספרות מותרות
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStudentPopup} color="primary">
            ביטול
          </Button>
          <Button type="submit" color="primary">
            שלח
          </Button>
        </DialogActions>
      </form>
    </Dialog>
      ) : null}
      {/* <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>הודעה</DialogTitle>
        <DialogContent>
          {dialogText}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            סגור
          </Button>
        </DialogActions>
      </Dialog> */}
      
    </Container>
  );
}

export default StudentPage;

export function InsightCard({ value, color, label }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "6rem",
        textAlign: "center",
        width: { xs: "30%", md: "19%" },
        padding: { xs: "10px" },
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "1.4rem", md: "2.5rem" } }}
        color={color}
      >
        {value}
      </Typography>
      <Typography
        margin={"5px"}
        sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
        variant="h4"
      >
        {label}
      </Typography>
    </Card>
  );
}
