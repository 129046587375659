import React, { useState, useContext, useEffect } from 'react';
import { Typography, Card, Box, Grid, IconButton, TextField } from "@mui/material";
import { Container } from "@mui/system";
import { UserContext } from "../../hooks/useUser";
import LoadingScreen from "../../components/LoadingScreen";
import { appFetch } from "../../utils/fetch";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const OperatorDashboardPage = () => {
  const { operator } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState('אנא המתן בזמן שהמערכת מאתרת נתונים');
  const [editValues, setEditValues] = useState({}); // Stores edited values
  const [editMode, setEditMode] = useState({}); // Controls edit mode for each field

  const metaDataItems = [
    { label: "שם המפעיל", key: "Name", editable: true },
    { label: "שם העסק", key: "business_name", editable: true },
    { label: "דואר אלקטרוני", key: "", editable: false },
    { label: "טלפון נייד", key: "field6", editable: true },
    { label: "תעודת זהות", key: "IDOperator", editable: false },
    { label: "רשות", key: "account", editable: false },
    { label: "סטטוס", key: "field1", editable: true },
    { label: "שנת פעילות חוזה חתום", key: "field3", editable: true },
    { label: "סוג עוסק", key: "field24", editable: true },
    { label: "מספר עוסק", key: "field26", editable: true },
    { label: "תאריך חתימה על החוזה", key: "field2", editable: false },
    { label: "שם הפעילות", key: "field37", editable: true },
    { label: "פירוט על הפעילות", key: "field38", editable: true },
    { label: "לינק מידע על הפעילות", key: "field39", editable: true },
    { label: "פרטים נוספים לפורטל", key: "field40", editable: true },
  ];

  const handleEditClick = (key) => {//אחראי על איזה שדה נכנס למצב עריכה
    setEditMode((prev) => ({ ...prev, [key]: !prev[key] }));
    if (!editMode[key]) {
      setEditValues((prev) => ({ ...prev, [key]: operator[key] }));
    }
  };

  const handleSaveClick = async (key) => {//שמירת הערך החדש בDB
    try {
      const updatedValue = editValues[key];
      const response = await appFetch(`api/zohoOperators/updateField`, {
        method: 'POST',
        body: JSON.stringify({ key, value: updatedValue, id: operator.id }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update field');
      }
      alert(`התוכן:  ${updatedValue} עודכן בהצלחה`);
      
      // Update locally
      operator[key] = updatedValue;
      setEditMode((prev) => ({ ...prev, [key]: false }));
    } catch (error) {
      console.error("Error updating field:", error);
      alert("שגיאה בעדכון השדה");
    }
  };

  const handleInputChange = (key, value) => {//מחזיק את השינוי בערך בשדה שנמצא במצב עריכה
    setEditValues((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Container sx={{ height: '100vh' }}>  
      {operator && (
        <Typography mb={2} variant="h3" color="appPink.main">
          פרטים אישיים עבור {operator?.Name || ''}
        </Typography>
      )}
      {loading ? (
        <LoadingScreen />
      ) : operator ? (
        <Grid container spacing={3} mb={3}>
          {metaDataItems.map((i) => (
            <Grid item key={i.key} xs={12} lg={2} mt={0} className="mobile-gap">
              <Typography fontWeight={800}>{i.label}</Typography>
              <Box display="flex" alignItems="center" mt={1}>
                {editMode[i.key] && i.editable ? (
                  <TextField
                    value={editValues[i.key]}
                    onChange={(e) => handleInputChange(i.key, e.target.value)}
                    variant="standard"
                    size="small"
                    fullWidth
                  />
                ) : (
                  <Typography>
                    {i.key === "account" && typeof operator[i.key] === "object" && operator[i.key] !== null
                      ? operator[i.key].name
                      : operator[i.key]}
                  </Typography>
                )}
                {i.editable && (
                  <IconButton
                    onClick={() => editMode[i.key] ? handleSaveClick(i.key) : handleEditClick(i.key)}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    {editMode[i.key] ? <SaveIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{noData}</Typography>
      )}
    </Container>
  );
};

export default OperatorDashboardPage;